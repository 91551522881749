@import '~swiper/css/swiper.min.css';

@mixin swiper-pagination {
    .swiper-pagination {
        display: block;
        position: relative;

        button {
            &.swiper-pagination-bullet {
                background: $cool-grey7;
                opacity: 1;
                width: step(1.5);
                height: step(1.5);
                margin: 0 step(1);

                &-active {
                    background: $coral;
                }
            }
        }
    }
}

@mixin swiper-navigation {
    .swiper-navigation-prev,
    .swiper-navigation-next {
        @include center-vertically;
        height: step(5.5);
        z-index: 10;
        cursor: pointer;

        &.swiper-button-disabled {
            opacity: 0.35;
            pointer-events: none;
            cursor: default;
        }
    }

    .swiper-navigation-prev {
        left: 0;
    }

    .swiper-navigation-next {
        right: 0;
    }
}

.swiper-container {
    .swiper-wrapper {
        box-sizing: inherit;
    }

    .swiper-slide {
        &:last-child {
            // stylelint-disable declaration-no-important
            margin-right: 0 !important;
            // stylelint-enable declaration-no-important
        }
    }

    @include swiper-pagination;
    @include swiper-navigation;
}

// custom when controls exist outside the swiper-container
.carousel-with-controls {
    position: relative;
    padding: 0 step(6);
}

// also need to be include outside of the carousel
@include swiper-pagination;
@include swiper-navigation;
