@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import 'styles/index';

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Bold';
    src: url('./assets/fonts/Lato-Bold.ttf');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('./assets/fonts/Lato-Regular.ttf');
}

@font-face {
    font-family: 'Lato-Light';
    src: url('./assets/fonts/Lato-Light.ttf');
}

@font-face {
    font-family: 'Lato-Thin';
    src: url('./assets/fonts/Lato-Thin.ttf');
}
