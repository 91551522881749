$breakpoints: (
    small: $breakpoints-sm * 1px,
    medium: $breakpoints-md * 1px,
    large: $breakpoints-lg * 1px,
    xlarge: $breakpoints-xl * 1px
);

$max-breakpoints: (
    small: ($breakpoints-sm - 1) * 1px,
    medium: ($breakpoints-md - 1) * 1px,
    large: ($breakpoints-lg - 1) * 1px,
    xlarge: ($breakpoints-xl - 1) * 1px
);

$there-is-no-higher-breakpoint: 9999em;

$allowed-breakpoint-targets: inspect(map-keys($breakpoints));

@function get-breakpoint($target) {
    $breakpoint: map-get($breakpoints, $target);

    @if $breakpoint == null {
        @warn('You tried to respond_to \'#{$target}\'. Please use one of these available breakpoints - #{$allowed-breakpoint-targets}');
    }

    @return $breakpoint;
}

@function get-max-breakpoint($target) {
    $breakpoint: map-get($max-breakpoints, $target);

    @if $breakpoint == null {
        @warn('You tried to respond_to \'#{$target}\'. Please use one of these available breakpoints - #{$allowed-breakpoint-targets}');
    }

    @return $breakpoint;
}

@mixin when-wider-than($target) {
    @media screen and (min-width: get-breakpoint($target)) {
        @content;
    }
}

@mixin when-smaller-than($target) {
    @media screen and (max-width: get-max-breakpoint($target)) {
        @content;
    }
}
