.cdk-overlay-container {
    @include stack(modal);

    .cdk-overlay-backdrop-showing {
        opacity: 1;
    }

    mat-dialog-container {
        position: relative;
        margin: auto;
        height: 100%;
        padding: 0;
        animation: modal-in 0.2s;
    }

    .mat-dialog-container,
    .mat-dialog-content {
        padding: 0;
        border-radius: 0;
    }
}

.ui-dropdown-label {
    @include font-select(standard, 16, $cool-grey11);
}

.cdk-global-scrollblock {
    overflow-y: visible;
}

.cdk-global-overlay-wrapper {
    position: relative;
}

.cdk-overlay-pane {
    max-width: step(120);
    max-height: 100%;
    margin: step(2);
    overflow-y: auto;
    border-radius: step(0.5);
    -webkit-overflow-scrolling: touch;

    &.toast-modal {
        margin: 0;
        border-radius: 0;
    }

    &.cart-edit {
        width: 100%;
    }
}

@keyframes modal-in {
    0% {
        transform: translate3d(0, 2px, 0);
        opacity: 0;
    }

    25% {
        transform: translate3d(0, 0, 0);
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
