$xfinity-standard-fallback:   helvetica, arial, sans-serif;

// $xfinity-standard:            'Montserrat', #{$xfinity-standard-fallback};
$xfinity-standard:            'Montserrat';
// $xfinity-standard-condensed:  'Lato', #{$xfinity-standard-fallback};
$xfinity-standard-condensed:  'Lato';

@mixin xfinity-standard-thin {
    @font-face {
        font-family: 'Montserrat';
        src: url('../assets/fonts/Montserrat-Thin.ttf') format('truetype');
        font-weight: 100;
        font-style: normal;
    }
}

@mixin xfinity-standard-thin-italic {
    @font-face {
        font-family: 'Montserrat';
        src: url('../assets/fonts/Montserrat-ThinItalic.ttf') format('truetype');
        font-weight: 100;
        font-style: italic;
    }
}

@mixin xfinity-standard-light {
    @font-face {
        font-family: 'Montserrat';
        src: url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
    }
}

@mixin xfinity-standard-light-italic {
    @font-face {
        font-family: 'Montserrat';
        src: url('../assets/fonts/Montserrat-LightItalic.ttf') format('truetype');
        font-weight: 300;
        font-style: italic;
    }
}

@mixin xfinity-standard-regular {
    @font-face {
        font-family: 'Montserrat';
        src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
    }
}

@mixin xfinity-standard-regular-italic {
    @font-face {
        font-family: 'Montserrat';
        src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
        font-weight: 400;
        font-style: italic;
    }
}

@mixin xfinity-standard-bold {
    @font-face {
        font-family: 'Montserrat';
        src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
    }
}

@mixin xfinity-standard-bold-italic {
    @font-face {
        font-family: 'Montserrat';
        src: url('../assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
        font-weight: 700;
        font-style: italic;
    }
}

@mixin xfinity-secondary-bold {
    @font-face {
        font-family: 'Lato';
        src: url('../assets/fonts/Lato-Bold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
    }
}

@mixin xfinity-secondary-thin {
    @font-face {
        font-family: 'Lato';
        src: url('../assets/fonts/Lato-Thin.ttf') format('truetype');
        font-weight: 100;
        font-style: normal;
    }
}

@mixin xfinity-secondary-thin-italic {
    @font-face {
        font-family: 'Lato';
        src: url('../assets/fonts/Lato-ThinItalic.ttf') format('truetype');
        font-weight: 100;
        font-style: italic;
    }
}

@mixin xfinity-secondary-light {
    @font-face {
        font-family: 'Lato';
        src: url('../assets/fonts/Lato-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
    }
}

@mixin xfinity-secondary-light-italic {
    @font-face {
        font-family: 'Lato';
        src: url('../assets/fonts/Lato-LightItalic.ttf') format('truetype');
        font-weight: 300;
        font-style: italic;
    }
}

@mixin xfinity-secondary-regular {
    @font-face {
        font-family: 'Lato';
        src: url('../assets/fonts/Lato-Regular.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
    }
}

@mixin xfinity-secondary-regular-italic {
    @font-face {
        font-family: 'Lato';
        src: url('../assets/fonts/Lato-Regular.ttf') format('truetype');
        font-weight: 400;
        font-style: italic;
    }
}

@mixin xfinity-secondary-bold {
    @font-face {
        font-family: 'Lato';
        src: url('../assets/fonts/Lato-Bold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
    }
}

@mixin xfinity-secondary-bold-italic {
    @font-face {
        font-family: 'Lato';
        src: url('../assets/fonts/Lato-BoldItalic.ttf') format('truetype');
        font-weight: 700;
        font-style: italic;
    }
}

@mixin xfinity-standard-all {
    @include xfinity-standard-thin;
    @include xfinity-standard-thin-italic;
    @include xfinity-standard-light;
    @include xfinity-standard-light-italic;
    @include xfinity-standard-regular;
    @include xfinity-standard-regular-italic;
    @include xfinity-standard-bold;
    @include xfinity-standard-bold-italic;
    @include xfinity-secondary-thin;
    @include xfinity-secondary-thin-italic;
    @include xfinity-secondary-light;
    @include xfinity-secondary-light-italic;
    @include xfinity-secondary-regular;
    @include xfinity-secondary-regular-italic;
    @include xfinity-secondary-bold;
    @include xfinity-secondary-bold-italic;
}
