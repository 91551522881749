// stylelint-disable selector-no-vendor-prefix
::-ms-clear {
    display: none;
}

::-webkit-input-placeholder {
    color: $cool-grey11;
}

::-moz-placeholder {
    color: $cool-grey11;
}

:-ms-input-placeholder {
    color: $cool-grey11;
}
// stylelint-enable selector-no-vendor-prefix
