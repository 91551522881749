// base types
@mixin btn-base() {
    @include font-select(bold, 12);
    display: inline-block;
    vertical-align: middle;

    @include when-smaller-than(medium) {
        width: 100%;
    }

    @include when-wider-than(medium) {
        @include font-select(bold, 14);
    }
}

@mixin btn-primary-secondary() {
    // remove 1px of padding for the border width
    padding: calc(#{vr(1)} - 2px) step(3);
    border-radius: step(0.5);
    border: 1px solid $transparent;

    @include when-wider-than(medium) {
        padding: calc(#{vr(1)} + 3px) step(3);
    }
}

// thematic mixins
@mixin btn-primary-light() {
    @include btn-base();
    @include btn-primary-secondary();

    color: $white;
    padding: calc(#{vr(1)} - 1px) step(3);
    background-color: $coral;
    letter-spacing: 1.5px;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        background-color: $coral;
    }

    &:not(.disabled):active {
        background-color: $coral;
    }

    &.disabled {
        cursor: default;
        background-color: $cool-grey6;
    }

    @include when-wider-than(medium) {
        padding: calc(#{vr(2)} - 4px) step(3);
    }
}

@mixin btn-primary-dark() {
    @include btn-base();
    @include btn-primary-secondary();

    color: $white;
    background-color: $deep-blue;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        background-color: $ocean-blue;
    }

    &:not(.disabled):active {
        background-color: $dark-blue;
    }

    &.disabled {
        cursor: default;
        color: $cool-grey9;
        background-color: $cool-grey12;
    }
}

@mixin btn-primary-blue() {
    @include btn-base();
    @include btn-primary-secondary();

    color: $white;
    border-color: $white;
    background-color: $transparent;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        color: $sharp-blue;
        background-color: $white;
    }

    &:not(.disabled):active {
        color: $sharp-blue;
        background-color: $white;
    }
}

@mixin btn-secondary-light() {
    @include btn-base();
    @include btn-primary-secondary();

    color: $black;
    border: 2px solid $black;
    background-color: $white;
    letter-spacing: 1.5px;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        color: $black;
        border-color: $black;
    }

    &:not(.disabled):active {
        color: $dark-blue;
        border-color: $dark-blue;
    }

    &.disabled {
        cursor: default;
        color: $cool-grey9;
        border-color: $cool-grey9;
    }
}

@mixin btn-secondary-dark() {
    @include btn-base();
    @include btn-primary-secondary();

    color: $vivid-blue;
    border-color: $vivid-blue;
    background-color: $transparent;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        color: $vivid-blue-hover;
        border-color: $deep-blue;
    }

    &:not(.disabled):active {
        color: $vivid-blue-hover;
        border-color: $ocean-blue;
    }

    &.disabled {
        cursor: default;
        color: $cool-grey11;
        border-color: $cool-grey11;
    }
}

@mixin btn-secondary-blue() {
    @include btn-base();

    border: 0;
    color: $white;
    background-color: $transparent;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        color: $white;
    }

    &:not(.disabled):active {
        color: $white;
    }
}

@mixin btn-link-light() {
    @include btn-base();

    border: 0;
    color: $coral;
    vertical-align: baseline;
    background: $transparent;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        color: $coral;
    }

    &:not(.disabled):active {
        color: $coral;
    }

    &.disabled {
        color: $cool-grey9;
        cursor: default;
    }
}

@mixin btn-link-dark() {
    @include btn-base();

    border: 0;
    color: $vivid-blue;
    vertical-align: baseline;
    background: $transparent;

    &:not(.disabled):hover,
    &:not(.disabled):focus {
        color: $vivid-blue-hover;
    }

    &:not(.disabled):active {
        color: $vivid-blue-hover;
    }

    &.disabled {
        color: $cool-grey11;
        cursor: default;
    }
}
