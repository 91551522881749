@import 'buttons';

*,
*::before,
*::after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: $transparent;
    margin: 0;
    padding: 0;
}

html {
    box-sizing: border-box;
    font-size: 100%; // 16px
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    color: $cool-grey13;
    font-family: $xfinity-standard;
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 400;
    font-style: normal;
    overflow-x: hidden;

    // *:focus {
    //     outline: 0;
    // }

    *::-moz-focus-inner {
        border: 0;
    }

    .white-outline {
        *:focus {
            outline: 1px solid $white;
        }
    }

    // *:focus {
    //     outline: 1px solid $sky-blue;
    // }

    &.show-outline {
        .white-outline {
            *:focus {
                outline: 1px solid $white;
            }
        }

        // *:focus {
        //     outline: 1px solid $sky-blue;
        // }

        input {
            &:not([type='radio']) {
                &:not([type='checkbox']) {
                    &:focus {
                        outline: 0;
                    }
                }
            }
        }
    }
}

html,
body {
    height: 100%;
}

button,
input,
select,
textarea {
    font-family: $xfinity-standard;
    line-height: normal;
}

a {
    color: $sky-blue;
    text-decoration: none;
    cursor: pointer;

    &[disabled] {
        pointer-events: none;
        cursor: default;
    }

    &:hover,
    &:active {
        outline: 0;
    }

    .no-touchevents &:hover {
        color: $vivid-blue;
    }

    .no-touchevents &:active {
        color: $dark-blue;
    }

    &.on-blue,
    &.on-dark-gray {
        color: $white;

        .no-touchevents &:hover {
            color: $vivid-blue;
        }

        .no-touchevents &:active {
            color: $vivid-blue;
        }
    }
}

ul,
li {
    list-style: none;
    padding: 0;
}

button {
    padding: 0;
    cursor: pointer;
    position: relative;
    background: $transparent;
    border: 0;
}

b,
.bold {
    @include font-select(bold);
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0.01px;
}

legend {
    border: 0;
    margin: 0;
    padding: 0;
}

.accessibility-hidden {
    position: absolute;
    display: block;
    visibility: visible;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
}

div[name='siteFooter'] {
    @include when-smaller-than(medium) {
        width: 100%;
        height: 418px;
    }

    @include when-wider-than(medium) {
        width: 768px;
        height: 396px;
    }

    @include when-wider-than(large) {
        width: 1440px;
        height: 676px;
    }

    @include when-wider-than(xlarge) {
        width: 100%;
        height: 676px;
    }
}

input {
    height: vr(6);
    padding: vr(1);
    background: $transparent;
    appearance: none;

    &[type='text'],
    &[type='number'] {
        font-size: vr(2);
        border: 1px solid $cool-grey7;
    }

    // stylelint-disable declaration-no-important
    &[xmMask] {
        float: none !important;
    }

    // stylelint-enable declaration-no-important
}

label {
    position: relative;

    input {
        &[type='radio'],
        &[type='checkbox'] {
            position: absolute;
            top: -2px;
            left: -2px;
            height: calc(100% + 4px);
            width: calc(100% + 4px);
            box-sizing: content-box;
            padding: 0;
            box-shadow: none;
            border: 0;
            z-index: -1;
            cursor: pointer;

            &[disabled],
            &.disabled {
                pointer-events: none;
                cursor: default;
            }

            &::-ms-check {
                border: 0;
                box-shadow: none;
            }
        }
    }
}

.superscript {
    line-height: 0;
    font-size: 50%;
    vertical-align: super;
}

.error-message {
    @include font-select(bold, 11, $cherry-red2, $spacing: 1.5, $transform: upper, $font: 'Montserrat');
}

.error {
    @include span(full);
    @include font-select(bold, 11, $cherry-red2, $spacing: 1.5, $transform: upper, $font: 'Montserrat');
    line-height: vr(2.5);
}

.visible {
    opacity: 1;
    visibility: visible;
}

.hidden {
    opacity: 0;
    visibility: hidden;
}

.display-none {
    display: none;
}

// global btn classes - keeps css dry; always start here
.xm-btn-primary-light {
    @include btn-primary-light();
}

.xm-btn-primary-dark {
    @include btn-primary-dark();
}

.xm-btn-primary-blue {
    @include btn-primary-blue();
}

.xm-btn-secondary-light {
    @include btn-secondary-light();
}

.xm-btn-secondary-dark {
    @include btn-secondary-dark();
}

.xm-btn-secondary-blue {
    @include btn-secondary-blue();
}

.xm-btn-link-light {
    @include btn-link-light();
    height: 20px;
    overflow-wrap: break-word;
}

.xm-btn-link-dark {
    @include btn-link-dark();
}

.xm-btn-secondary-dark,
.xm-btn-secondary-light {
    &.promise-btn {
        &.is-loading {
            background: $cool-grey6;
        }
    }
}

.plan-details-cms-text-bullets {
    list-style-type: disc;

    @include font-select(standard, 16, $black, $font:'Lato');
    margin-top: vr(3);
    line-height: 24px;

    &:not(:last-child) {
        margin-top: vr(3);
        margin-bottom: vr(3);
    }

    @include when-smaller-than(small) {
        padding-left: step(0.75);
    }

    &.top-margin-0 {
        margin-top: 0;
    }
}

.marketing-grid-text-subtext {
    font-size: 16px;
    line-height: 24px;
    $font: 'Lato';
    display: inline-block;

    @include when-wider-than(small) {
        max-width: 100%;
        font-size: 14px;
        line-height: 22px;
    }

    @include when-wider-than(medium) {
        max-width: 264px;
        font-size: 16px;
        line-height: 24px;
    }

    @include when-wider-than(large) {
        max-width: 264px;
        font-size: 16px;
        line-height: 24px;
    }
}

.mtext-subtext {
    @include when-wider-than(small) {
        //   float: left;
    }
}

.plandetailsheader {
    $font: 'Montserrat';
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: $black;
    text-align: left;
    display: block;
}

.plandetailsbody {
    $font: 'Lato';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $black;
}

.plan-details-hero-small {
    $font: 'Montserrat';
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: $black;
}

.plan-details-hero-header {
    $font: 'Montserrat';
    font-weight: bold;
    color: $black;

    @include when-wider-than(small) {
        font-size: 27px;
        line-height: 33px;
    }

    @include when-wider-than(medium) {
        font-size: 30px;
        line-height: 40px;
    }

    @include when-wider-than(large) {
        font-size: 36px;
        line-height: 44px;
    }
}

.plan-details-hero-body {
    $font: 'Lato';
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $black;
}

.pl-mobile-sm {
    padding-left: step(2);
    margin-top: step(2);
    margin-bottom: step(5);
    margin-right: 6px;

    @include when-wider-than(large) {
        margin-block: 0;
    }
}

.plan-price-heading {
    @include font-select(bold, 16, $black, $font: 'Lato');
    line-height: 18px;
    display: block;
    text-align: center;

    @include when-wider-than(medium) {
        &.unlimited-subtitle {
            @include font-select(bold, 18, $black, $font: 'Lato');
            line-height: 24px;
        }
    }
}
