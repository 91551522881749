@function step($multiplier) {
    @return $multiplier * $global-step-size;
}

@function vr($lines) {
    @return rem(step($lines)); // 8px for 1 line
}

@mixin half-width-gutter() {
    width: calc(#{span(6)} - #{step(1.5)});
}

@mixin center($offset-v: 0, $offset-h: 0) {
    position: absolute;
    top: 50% + $offset-v;
    left: 50% + $offset-h;
    transform: translate(-50%, -50%);
    perspective: 1px;
}

@mixin center-vertically($offset: 0) {
    position: absolute;
    top: 50% + $offset;
    transform: translateY(-50%);
    perspective: 1px;
}

@mixin center-horizontally($offset: 0) {
    position: absolute;
    left: 50% + $offset;
    transform: translateX(-50%);
    perspective: 1px;
}

@mixin display-card-padding() {
    @include pad(0.5);

    @include when-wider-than(large) {
        @include pad(1);
    }
}
