// stylelint-disable at-rule-empty-line-before
@use 'sass:math';

@mixin cloudinary-background($path, $extras) {
    @if ($extras) {
        background: url('https://img.xfinitymobile.com/image/upload/f_auto,q_auto,fl_lossy/client/#{$path}') $extras;
    } @else {
        background-image: url('https://img.xfinitymobile.com/image/upload/f_auto,q_auto,fl_lossy/client/#{$path}');
    }
}

@mixin cloudinary-icon($path, $extras) {
    @if ($extras) {
        background: url('https://img.xfinitymobile.com/client/#{$path}') $extras;
    } @else {
        background-image: url('https://img.xfinitymobile.com/client/#{$path}');
    }
}

@function rem($pxval) {
    @if not unitless($pxval) {
        $pxval: math.div($pxval, $pxval * 0 + 1);
    }

    $base: $global-default-font-size;
    @if not unitless($base) {
        $base: math.div($base, $base * 0 + 1);
    }
    @return math.div($pxval, $base) * 1rem;
}

@mixin stack($name) {
    $base: 1000;
    $mult: 10;

    $names: (
        background: $base - $mult * 1,
        base: $base,
        foreground: $base + $mult * 1,
        foreground-pop: $base + $mult * 2,
        header: $base + $mult * 3,
        modal-shadow: $base + $mult * 4,
        modal: $base + $mult * 5
    );

    z-index: map-get($names, $name);
}

@function span($column) {
    @if ($column == full) {
        @return 100%;
    } @else {
        @return math.div($column, 12) * 100%;
    }
}

@mixin span($column, $float: left) {
    float: $float;
    width: span($column);
}

@mixin container($max-width: 100%) {
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width;

    &::after {
        content: ' ';
        display: block;
        clear: both;
    }
}

@mixin pre($column) {
    margin-left: span($column);
}

@mixin post($column) {
    margin-right: span($column);
}

@mixin prefix($column) {
    padding-left: span($column);
}

@mixin suffix($column) {
    padding-right: span($column);
}

@mixin pad($column-l, $column-r: $column-l) {
    @include prefix($column-l);
    @include suffix($column-r);
}

@mixin squish($column-l, $column-r: $column-l) {
    @include pre($column-l);
    @include post($column-r);
}

@mixin min-page-height($offset: 0) {
    min-height: calc(100vh - #{$footer-small-height + $offset});

    @include when-wider-than(medium) {
        min-height: calc(100vh - #{$footer-medium-height + $offset});
    }

    @include when-wider-than(large) {
        min-height: calc(100vh - #{$footer-large-height + $offset});
    }

    @include when-wider-than(xlarge) {
        min-height: calc(100vh - #{$footer-xlarge-height + $offset});
    }
}
// stylelint-enable at-rule-empty-line-before

// Display (Headers)
@mixin display-2-font() {
    @include font-select(bold, 48, $black, $font: 'Montserrat');
    line-height: vr(7);
}

@mixin display-3-font() {
    @include font-select(bold, 36, $black, $font: 'Montserrat');
    line-height: vr(5.5);
}

@mixin display-4-font($color: $black) {
    @include font-select(bold, 24, $color, $font: 'Montserrat');
    line-height: vr(4);
}

@mixin display-5-font() {
    @include font-select(bold, 18, $black, $font: 'Montserrat');
    line-height: vr(3.25);
}

@mixin display-6-font() {
    @include font-select(bold, 20, $black, $font: 'Montserrat');
    line-height: vr(3.5);
}

@mixin display-custom-font($font-size: 18, $spacing: 1) {
    @include font-select(bold, $font-size, $black, $font: 'Montserrat');
    line-height: #{$font-size + 8}px;
    letter-spacing: $spacing;
}

@mixin cta-hyperlink($size: large) {
    text-decoration: underline;
    line-height: vr(3);

    @if ($size == large) {
        @include font-select(standard, 16, $core-blue, $spacing: 0, $font: 'Lato');
    }

    @if ($size == medium) {
        @include font-select(standard, 14, $core-blue, $spacing: 0, $font: 'Lato');
    }
}

// Subhead
@mixin subhead-1-font($color: $black, $spacing: 1) {
    @include font-select(standard, 24, $color);
    line-height: vr(4);
    letter-spacing: #{$spacing}px;
}

@mixin subhead-2-font($color: $black, $spacing: 1, $weight: standard) {
    @include font-select($weight, 18, $color);
    line-height: vr(3.25);
    letter-spacing: #{$spacing}px;
}

// Body
@mixin body-font($size: large, $color: $black, $weight: standard, $spacing: 0) {
    letter-spacing: #{$spacing}px;

    @if ($size == xlarge) {
        @include font-select($weight, 24, $color, $font: 'Lato');
        line-height: vr(4);
    }

    @if ($size == mlarge) {
        @include font-select($weight, 18, $color, $font: 'Lato');
        line-height: vr(3.25);
    }

    @if ($size == large) {
        @include font-select($weight, 16, $color, $font: 'Lato');
        line-height: vr(3);
    }

    @if ($size == medium) {
        @include font-select($weight, 14, $color, $font: 'Lato');
        line-height: vr(2.75);
    }

    @if ($size == small) {
        @include font-select($weight, 13, $color, $font: 'Lato');
        line-height: vr(2.5);
    }

    @if ($size == xsmall) {
        @include font-select($weight, 11, $color, $font: 'Lato');
        line-height: vr(1.75);
    }
}

// Global Type Elements
@mixin sentence-case($size: large, $color: $black, $weight: standard) {
    @if ($size == large) {
        @include font-select($weight, 16, $color, $spacing: 1.5, $font: 'Lato');
        line-height: vr(3);
    }

    @if ($size == small) {
        @include font-select($weight, 14, $color, $spacing: 0, $font: 'Lato');
        line-height: vr(2.75);
    }
}

// CTAs
@mixin cta-tertiary($size: large, $color: $core-blue) {
    line-height: vr(2.5);

    @if ($size == large) {
        @include font-select(bold, 16, $color, $transform:upper, $spacing: 1.5, $font: 'Montserrat');
    }

    @if ($size == medium) {
        @include font-select(bold, 14, $color, $transform:upper, $spacing: 1.5, $font: 'Montserrat');
        line-height: vr(2.75);
    }

    @if ($size == small) {
        @include font-select(bold, 12, $color, $transform:upper, $spacing: 1.5, $font: 'Montserrat');
    }
}

// Element mixins

// Error Message
@mixin error-message-label() {
    @include font-select(bold, 14, $cherry-red2, $font: 'Montserrat');
    line-height: vr(2.5);
}

//footer
@mixin footer-header() {
    @include font-select(bold, 18, $white, $spacing: 0, $font:'Lato');
    line-height: vr(3.25);
}

// Overview/Landing page
@mixin landing-title-container() {
    display: block;
    position: relative;
    margin: 0 0 vr(5) 0;
}

@mixin landing-headers() {
    @include font-select(bold, 27, $black, $font: 'Montserrat');
    line-height: vr(4.125);

    @include when-wider-than(medium) {
        @include display-3-font();
    }
}

@mixin landing-subtitles() {
    @include body-font(large);
    margin: auto;
    padding-top: vr(1.75);
    max-width: step(43);

    @include when-wider-than(medium) {
        padding-top: vr(2);
        max-width: step(88);
    }

    @include when-wider-than(large) {
        @include subhead-2-font();
        padding-top: vr(3);
        max-width: step(111);
    }
}

@mixin landing-container($background: none) {
    @include span(full, initial);
    text-align: center;
    padding: vr(5) step(2);
    margin: vr(4) 0;
    background-color: $background;

    @include when-wider-than(medium) {
        padding: vr(5) vr(4);
    }

    @include when-wider-than(large) {
        padding: vr(5) step(2);
    }
}

@mixin display-learnmorelink-small() {
    @include font-select(standard, 14);
    text-decoration: underline;
    line-height: vr(3);
}
// Checkout Headers
@mixin checkout-header() {
    @include font-select(bold, 27, $black, $font: 'Montserrat');
    line-height: vr(4.125);

    @include when-wider-than(medium) {
        @include font-select(bold, 30, $black, $font: 'Montserrat');
        line-height: vr(5);
    }

    @include when-wider-than(large) {
        @include display-3-font();
    }
}

@mixin checkout-section-headers() {
    @include font-select(bold, 27, $black, $font: 'Montserrat');
    line-height: vr(4);

    @include when-wider-than(medium) {
        @include font-select(bold, 30, $black, $font: 'Montserrat');
        line-height: vr(5);
    }

    @include when-wider-than(large) {
        @include display-2-font();
    }
}

//Small Desktop header
@mixin small-header() {
    @include font-select(bold, 27, $black, $font: 'Montserrat');
    line-height: vr(4.125);

    @include when-wider-than(medium) {
        @include font-select(bold, 30, $black, $font: 'Montserrat');
        line-height: vr(5);
    }

    @include when-wider-than(large) {
        @include display-4-font();
    }
}

@mixin subtitle-font($size: large, $color: $core-blue) {
    line-height: vr(2.5);

    @if ($size == large) {
        @include font-select(bold, 16, $color, $transform:upper, $spacing: 1.5, $font: 'Montserrat');
        line-height: vr(2.75);
    }

    @if ($size == medium) {
        @include font-select(bold, 14, $color, $transform:upper, $spacing: 1.5, $font: 'Montserrat');
        line-height: vr(2.75);
    }

    @if ($size == small) {
        @include font-select(bold, 12, $color, $transform:upper, $spacing: 1.5, $font: 'Montserrat');
    }
}
